import {Component, OnDestroy} from '@angular/core';
import {CaseService} from '../../services/case.service';
import {Store} from '@ngrx/store';
import {ICaseState} from '../../store/case-state.interface';
import {Subscription} from 'rxjs';
import * as CaseActions from '../../store/case.actions';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {RightsManagementService} from '../../services/rights-management.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ICaseStatus} from '../../models/case-status.interface';
import {IUser} from '../../models/user.interface';
import {UserService} from '../../services/user.service';
import {IUserState} from '../../store/user-state.interface';

@Component({
  selector: 'case-search',
  templateUrl: 'case-search.component.html',
  styleUrls: ['case-search.component.css']
})
export class CaseSearchComponent implements OnDestroy {
  searchCasesInProgress = false;
  searchPersonsInProgress = false;
  searchResultsCases = [];
  searchResultsPersons = [];
  searchResultMode = '';
  searchstringCase = '';
  searchstringBirthplace = '';
  searchstringCourt = '';
  searchstringSource = '';
  searchstringPerson = '';
  limitToStatus: number = -1;
  caselistForStatus = 0;
  startDateForList = new Date(Date.UTC(new Date().getFullYear() - 1, 8, 1, 0, 0, 0)).toISOString().substring(0, 10);
  endDateForList = new Date(Date.UTC(new Date().getFullYear(), 7, 30, 0, 0, 0)).toISOString().substring(0, 10);
  limitResultsToUser = 0;
  realestate = 0;
  users: IUser[] = [];
  userStateSub: Subscription;
  order = 0;
  searchResultSub: Subscription;
  caseStatus: ICaseStatus[];
  caseStatusSub: Subscription;
  showInfoCase = false;
  showInfoPerson = false;

  constructor(private caseService: CaseService,
              private modalService: NgbModal,
              private userService: UserService,
              private store: Store<{case: ICaseState, users: IUserState, caseStatus: ICaseStatus[]}>,
              public rightsManagementService: RightsManagementService) {
    this.caseStatusSub = this.store.select('caseStatus').subscribe(
      (caseStatus) => { this.caseStatus = caseStatus; }
    );
    this.searchResultSub = this.store.select('cases').subscribe(
      (caseState) => {
        if (caseState) {
          this.searchResultsCases = caseState.searchResultsCases;
          this.searchResultsPersons = caseState.searchResultsPersons;
          this.searchResultMode = caseState.searchResultMode;
        }
      }
    );
    this.userStateSub = this.store.select('users').subscribe(
      (userState) => {
        if (userState) { this.users = userState.users; }
      }
    );
  }

  doSearchCases() {
    this.searchResultMode = '';
    this.searchstringPerson = '';
    this.searchCasesInProgress = true;
    this.caseService.searchCases(this.searchstringCase, this.limitToStatus, this.order, this.limitResultsToUser, this.searchstringBirthplace, this.searchstringCourt, this.searchstringSource, this.realestate).subscribe(
      (results) => {
        this.store.dispatch(new CaseActions.ReplaceCaseSearchResults(results));
        this.store.dispatch(new CaseActions.ReplaceSearchResultMode('cases'));
      },
      (error) => {
        this.searchCasesInProgress = false;
      },
      () => {
        this.searchCasesInProgress = false;
      }
     );
  }

  showListExcel() {
    this.caseService.getExcelCaseList(this.caselistForStatus);
  }

  showListScreen() {
    this.caseService.goToCaseListScreen(this.caselistForStatus);
  }

  showAuskehrungExcel() {
    this.caseService.getExcelAuskehrungList(this.startDateForList, this.endDateForList, 'Variante 1');
  }

  showProzentregelExcel() {
    this.caseService.getExcelAuskehrungList(this.startDateForList, this.endDateForList, 'Variante 2');
  }

  showAuskehrungScreen() {
    this.caseService.goToAuskehrungListScreen(this.startDateForList, this.endDateForList, 'Variante 1');
  }

  showProzentregelScreen() {
    this.caseService.goToAuskehrungListScreen(this.startDateForList, this.endDateForList, 'Variante 2');
  }

  doSearchPersons() {
    this.searchResultMode = '';
    this.searchstringCase = '';
    this.searchPersonsInProgress = true;
    this.caseService.searchPersons(this.searchstringPerson).subscribe(
      (results) => {
        this.store.dispatch(new CaseActions.ReplacePersonSearchResults(results));
        this.store.dispatch(new CaseActions.ReplaceSearchResultMode('persons'));
      },
      (error) => {
        this.searchPersonsInProgress = false;
      },
      () => {
        this.searchPersonsInProgress = false;
      }
     );
  }

  createCase() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = 'Wollen Sie wirklich einen neuen Fall anlegen?';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.createCaseConfirmed();
        }
      }
    );
  }

  createCaseConfirmed() {
    this.caseService.createNewCase();
  }

  ngOnDestroy(): void {
    this.searchResultSub.unsubscribe();
    this.caseStatusSub.unsubscribe();
    this.userStateSub.unsubscribe();
  }

  toggleInfoCase() {
    this.showInfoCase = !this.showInfoCase;
  }

  toggleInfoPerson() {
    this.showInfoPerson = !this.showInfoPerson;
  }
}
