<div *ngIf="rightsManagementService.isAllowedToCreateNewCases()" class="card col-12 mt-2">
  <div class="card-body">
    <div>
      <button (click)="createCase()" class="btn btn-success btn-sm m-1">neuen Fall anlegen</button>
    </div>
  </div>
</div>

<div class="card-deck">
  <div class="card mt-2">
    <h3 class="ilatitle">Fälle <span (click)="toggleInfoCase()" class="fa fa-sm fa-info-circle" title="Hinweis: UND-verknüpfte Suche zwischen mit Leerzeichen getrennten Suchbegriffen (Erblasser-Namen und Fall-Nummern
      bzw. Teile davon), es werden max. 500 Ergebnisse angezeigt"></span></h3>
    <p *ngIf="showInfoCase">Hinweis: UND-verknüpfte Suche zwischen mit Leerzeichen getrennten Suchbegriffen (Erblasser-Namen und Fall-Nummern
      bzw. Teile
      davon), es werden max. 500 Ergebnisse angezeigt</p>
    <div *ngIf="searchCasesInProgress" class="alert success"><span class="fa fa-spinner fa-spin"></span> Suche läuft...
    </div>
    <input (keyup.enter)="doSearchCases()" [readonly]="searchCasesInProgress || searchPersonsInProgress"
           [(ngModel)]="searchstringCase" placeholder="Suchbegriffe"
           class="form-control form-control-inline form-control-sm my-2">
    <input (keyup.enter)="doSearchCases()" [readonly]="searchCasesInProgress || searchPersonsInProgress"
           [(ngModel)]="searchstringCourt" id="court-string" placeholder="Einschränkung auf NLGs"
           class="form-control form-control-inline form-control-sm">
    <input (keyup.enter)="doSearchCases()" [readonly]="searchCasesInProgress || searchPersonsInProgress"
           [(ngModel)]="searchstringBirthplace" id="birthplace-string" placeholder="Einschränkung auf Geburtsort"
           class="form-control form-control-inline form-control-sm">
    <input (keyup.enter)="doSearchCases()" [readonly]="searchCasesInProgress || searchPersonsInProgress"
           [(ngModel)]="searchstringSource" id="source-string" placeholder="Einschränkung auf Quelle"
           class="form-control form-control-inline form-control-sm">
  <select name="group" class="form-control form-control-sm mb-2 mr-sm-2 mb-sm-0" [(ngModel)]="limitToStatus"
          id="formSelectCaseState">
    <option selected value="-1">Standard (alle aktiven Fälle)</option>
    <option value="0">alle Fälle außer Ablage</option>
    <option value="null">Fall-Status: keine Einschränkungen</option>
    <option *ngFor="let stat of caseStatus" [value]="stat.id">{{stat.description}}</option>
  </select>
  <select name="user" class="form-control form-control-sm mb-2 mr-sm-2 mb-sm-0" [(ngModel)]="limitResultsToUser"
          id="formSelectTeamMember">
    <option value="0" selected="selected">beteiligte Mitarbeiter: keine Einschränkungen</option>
    <option *ngFor="let user of users"
            [value]="user.id">
      {{user.abbreviation}} - {{user.first_name}} {{user.last_name}}
    </option>
  </select>
  <select name="realestate_limit" class="form-control form-control-sm mb-2 mr-sm-2 mb-sm-0" [(ngModel)]="realestate"
          id="realestate_limit">
    <option value="0">Immobilien: keine Einschränkungen</option>
    <option value="1">Immobilien: nur Fälle mit vorhandenen Immobilien anzeigen</option>
  </select>
  <select name="ordering" class="form-control form-control-sm mb-2 mr-sm-2 mb-sm-0" [(ngModel)]="order"
          id="formOrdering">
    <option value="0">neuere Fälle zuerst</option>
    <option value="1">ältere Fälle zuerst</option>
  </select>
  <button [disabled]="searchCasesInProgress || searchPersonsInProgress" class="btn btn-sm btn-success my-2"
          (click)="doSearchCases()">suchen
  </button>
</div>
<div class="card mt-2">
  <h3 class="ilatitle">Personen <span (click)="toggleInfoPerson()" class="fa fa-sm fa-info-circle" title="Hinweis: UND-verknüpfte Suche zwischen mit Leerzeichen getrennten Suchbegriffen (Vor- und Nachnamen und
    Personen-Nummern bzw. Teile davon), es werden max. 200 Ergebnisse angezeigt"></span></h3>
  <p *ngIf="showInfoPerson">Hinweis: UND-verknüpfte Suche zwischen mit Leerzeichen getrennten Suchbegriffen (Vor- und Nachnamen und
    Personen-Nummern bzw. Teile
    davon), es werden max. 200 Ergebnisse angezeigt</p>
  <p>
    Suchbegriffe
  </p>
  <div *ngIf="searchPersonsInProgress" class="alert success"><span class="fa fa-spinner fa-spin"></span> Suche
    läuft...
  </div>
  <input (keyup.enter)="doSearchPersons()" [readonly]="searchCasesInProgress || searchPersonsInProgress"
         [(ngModel)]="searchstringPerson"
         class="form-control form-control-inline form-control-sm my-2">
  <button [disabled]="searchCasesInProgress || searchPersonsInProgress" class="btn btn-sm btn-success my-2"
          (click)="doSearchPersons()">suchen
  </button>
  <ng-container *ngIf="rightsManagementService.isAllowedToSeeCaseList()">
    <h3 class="mt-3 ilatitle">Fall-Listen</h3>
    <div>
      <select name="group" class="form-control form-control-sm mb-2 mr-sm-2 mb-sm-0" [(ngModel)]="caselistForStatus"
          id="caselistForStatus">
        <option selected value="0">---bitte auswählen---</option>
        <option *ngFor="let stat of caseStatus" [value]="stat.id">{{stat.description}}</option>
      </select>
    </div>
    <div class="mt-2">
      <button class="btn btn-sm btn-success mx-2" [disabled]="+caselistForStatus === 0" (click)="showListScreen()">Liste zur Bearbeitung</button>
      <button class="btn btn-sm btn-success mx-2" [disabled]="+caselistForStatus === 0" (click)="showListExcel()">Liste als .xlsx</button>
    </div>
  </ng-container>
  <ng-container *ngIf="rightsManagementService.isAllowedToSeeAuskehrList()">
    <h3 class="mt-3 ilatitle">Auskehrungsliste</h3>
    <div class="row">
      <div class="col-1">von</div>
      <div class="col-3">
        <input type="date" class="form-control form-control-sm" [(ngModel)]="startDateForList">
      </div>
      <div class="col-1">bis</div>
      <div class="col-3">
        <input type="date" class="form-control form-control-sm" [(ngModel)]="endDateForList">
      </div>
    </div>
    <div class="mt-2">
      <button class="btn btn-sm btn-success mx-2" (click)="showAuskehrungScreen()">aktuelle Auskehrungen</button>
      <button class="btn btn-sm btn-success mx-2" (click)="showProzentregelScreen()">%-Regel</button>
      <button class="btn btn-sm btn-success mx-2" (click)="showAuskehrungExcel()">Auskehrungen als .xlsx</button>
      <button class="btn btn-sm btn-success mx-2" (click)="showProzentregelExcel()">%-Regel als .xlsx</button>
    </div>
  </ng-container>
</div>
</div>

<div class="card col-12 mt-2">
  <case-history></case-history>
  <heir-history></heir-history>
</div>

<case-list-search-results *ngIf="!searchCasesInProgress && !searchPersonsInProgress && (searchResultMode === 'cases')"
                          [searchResults]="searchResultsCases"></case-list-search-results>
<person-list-search-results
  *ngIf="!searchPersonsInProgress && !searchPersonsInProgress && (searchResultMode === 'persons')"
  [searchResults]="searchResultsPersons"></person-list-search-results>
