<div class="m-2 p-2">
  <h4>EBS-Doku</h4>
  <div *ngIf="!hasTrueStammbaum()">EBS/EV-Automatik nicht verfügbar, da für diesen Fall kein Stammbaum angelegt ist.</div>
  <ng-container *ngIf="hasTrueStammbaum()">
    <button *ngIf="isAllowedToESA()" class="btn btn-sm btn-primary mx-1" (click)="showEBSAntrag()">EBS-Antrag herunterladen</button>
    <button *ngIf="isAllowedToEV()" class="btn btn-sm btn-primary mx-1" (click)="showEVEntwurf()">EV-Entwurf herunterladen</button>
  </ng-container>
  <h5 class="ilatitle mt-3">Liste fehlender Urkunden</h5>
  <div *ngIf="!hasTrueStammbaum()">Nicht verfügbar, da für diesen Fall kein Stammbaum angelegt ist.</div>
  <table *ngIf="hasTrueStammbaum()">
    <tr>
      <td class="bold">Liste fehlender Urkunden</td>
      <td>
        <button class="btn btn-sm btn-success mx-1" (click)="zeigeFehlendeUrkunden(1)">sortiert nach Orten</button>
        <button class="btn btn-sm btn-success mx-1" (click)="zeigeFehlendeUrkunden(2)">sortiert nach Personen</button>
      </td>
    </tr>
    <tr>
      <td class="bold">Liste fehlender, nicht bestellter Urkunden</td>
      <td>
        <button class="btn btn-sm btn-success mx-1" (click)="zeigeFehlendeUrkunden(3)">sortiert nach Orten</button>
        <button class="btn btn-sm btn-success mx-1" (click)="zeigeFehlendeUrkunden(4)">sortiert nach Personen</button>
      </td>
    </tr>
  </table>
  <h5 class="ilatitle mt-3" *ngIf="editMode !== CaseSaveMode.EBS_Daten">Informationen für EBS-Antrag
    <button [disabled]="editMode !== CaseSaveMode.Kein_Modus_gewaehlt" (click)="setEditMode(CaseSaveMode.EBS_Daten)"
            class="mx-1 btn btn-sm btn-warning">bearbeiten
    </button>
  </h5>
  <h5 class="ilatitle mt-3" *ngIf="editMode === CaseSaveMode.EBS_Daten">Informationen für EBS-Antrag
    <button (click)="reloadData()" class="mx-1 btn btn-sm btn-info">abbrechen</button>
    <button (click)="saveData()" class="mx-1 btn btn-sm btn-danger">speichern</button>
  </h5>
  <div *ngIf="!hasTrueStammbaum">Informationen können nur angezeigt werden, wenn der Fall einen Stammbaum hat.</div>
  <div class="card-deck">
    <div class="card">
      <table *ngIf="copiedActiveCase && hasTrueStammbaum" class="table-responsive table-sm table-bordered">
        <tr>
          <td>Wert (Anteil iLANOT)</td>
          <td>
            <editfield-text [maxLength]="20" [editMode]="editMode === CaseSaveMode.EBS_Daten"
                            [(text)]="copiedActiveCase.value"></editfield-text>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Konkurrenz</td>
          <td></td>
          <td>
            <editfield-text [maxLength]="400" [editMode]="editMode === CaseSaveMode.EBS_Daten"
                            [(text)]="copiedActiveCase.konkurrenzfirmen"></editfield-text>
          </td>
        </tr>
        <tr>
          <td>auf EBS-Doku umgestellt</td>
          <td>
            <span *ngIf="editMode !== CaseSaveMode.EBS_Daten"
                  class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(copiedActiveCase.changed_to_ebs_doku_user)}}</span>
          </td>
          <td>
            {{copiedActiveCase.changed_to_ebs_doku_date | date: 'dd.MM.y'}}
          </td>
        </tr>
        <tr>
          <td>Fallführer</td>
          <td><span *ngFor="let u of getCaseLeaders()" class="badge badge-pill badge-success">{{userService.getAbbreviationForUser(u.user_id)}}</span></td>
          <td></td>
        </tr>
        <tr>
          <td>Zuständig für EBS-Doku</td>
          <td><span *ngFor="let u of getEBSDokuMembers()" class="badge badge-pill badge-danger">{{userService.getAbbreviationForUser(u.user_id)}}</span></td>
          <td></td>
        </tr>
        <tr>
          <td>SU Erblasser vorhanden</td>
          <td>
            <show-boolean-value [value]="stammbaumService.isSUForELAvailable()"></show-boolean-value>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Fiskus</td>
          <td>
            <show-boolean-value [value]="copiedActiveCase.fiscal"></show-boolean-value>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Akteneinsicht am</td>
          <td></td>
          <td>
            <editfield-date [(date)]="copiedActiveCase.date_of_record_access"
                            [editMode]="false"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>weitere Akteneinsichten</td>
          <td></td>
          <td>
            <editfield-text [maxLength]="100" [editMode]="false"
                            [(text)]="copiedActiveCase.weitere_akteneinsichten"></editfield-text>
          </td>
        </tr>
        <tr>
          <td>ausgeschlagene Erben</td>
          <td></td>
          <td>
            <ng-container *ngFor="let p of ausschlagungen">
              <div class="my-1">{{p.vorname}} {{p.nachname}}
                <show-date [value]="p.geburtsdatum"></show-date>
              </div>
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Erben im Ausland</td>
          <td></td>
          <td>
            <editfield-text [maxLength]="400" [editMode]="editMode === CaseSaveMode.EBS_Daten"
                            [(text)]="copiedActiveCase.erben_im_ausland"></editfield-text>
          </td>
        </tr>
        <tr>
          <td>unwillige Erben</td>
          <td></td>
          <td>
            <div class="my-1" *ngFor="let p of unwillige">{{p.vorname}} {{p.nachname}}
              <show-date [value]="p.geburtsdatum"></show-date>
            </div>
          </td>
        </tr>
        <tr>
          <td>nachverstorbene Erben</td>
          <td></td>
          <td>
            <ng-container *ngFor="let p of nachverstorbene">
              <div class="my-1">{{p.vorname}} {{p.nachname}}
                <show-date [value]="p.geburtsdatum"></show-date>
              </div>
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>Unterfälle</td>
          <td></td>
          <td>
            <table class="table table-sm"
                   *ngIf="copiedActiveCase.child_cases && copiedActiveCase.child_cases.length > 0">
              <tr *ngFor="let cc of copiedActiveCase.child_cases_data">
                <td>{{cc.id}}</td>
                <td>{{cc.first_name}}</td>
                <td>{{cc.last_name}}</td>
                <td>{{cc.birth_name}}</td>
                <td>
                  <button class="btn btn-sm btn-success" (click)="goToCase(cc.id)">direkt zu diesem Fall</button>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>

    <div class="card">
      <table *ngIf="copiedActiveCase && hasTrueStammbaum" class="table-responsive table-sm">
        <tr>
          <td>ESA angelegt</td>
          <td>
            <editfield-select *ngIf="editMode === CaseSaveMode.EBS_Daten" [(select)]="copiedActiveCase.esa_created_user"
                              [editMode]="true" [options]="userOptions"></editfield-select>
            <span *ngIf="editMode !== CaseSaveMode.EBS_Daten"
                  class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(copiedActiveCase.esa_created_user)}}</span>
          </td>
          <td>
            <editfield-date [editMode]="editMode === CaseSaveMode.EBS_Daten" [displayUnbekanntAsNo]="true"
                            [(date)]="copiedActiveCase.esa_created_date"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>Teilerbschein</td>
          <td>
            <editfield-checkbox [editMode]="editMode === CaseSaveMode.EBS_Daten"
                                [(checkbox)]="copiedActiveCase.teilerbschein"></editfield-checkbox>
          </td>
        </tr>
        <tr>
          <td>Gesamterbschein</td>
          <td>
            <editfield-checkbox [editMode]="editMode === CaseSaveMode.EBS_Daten"
                                [(checkbox)]="copiedActiveCase.gesamterbschein"></editfield-checkbox>
          </td>
        </tr>
        <tr>
          <td>AS zu ESA angelegt</td>
          <td>
            <editfield-select *ngIf="editMode === CaseSaveMode.EBS_Daten" [(select)]="copiedActiveCase.as_zu_esa_angelegt_user"
                              [editMode]="true" [options]="userOptions"></editfield-select>
            <span *ngIf="editMode !== CaseSaveMode.EBS_Daten"
                  class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(copiedActiveCase.as_zu_esa_angelegt_user)}}</span>
          </td>
          <td>
            <editfield-date [editMode]="editMode === CaseSaveMode.EBS_Daten" [displayUnbekanntAsNo]="true"
                            [(date)]="copiedActiveCase.as_zu_esa_angelegt_date"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>Übersetzungen nötig</td>
          <td>
            <editfield-checkbox [editMode]="editMode === CaseSaveMode.EBS_Daten"
                                [(checkbox)]="copiedActiveCase.uebersetzung_noetig"></editfield-checkbox>
          </td>
          <td>
            <editfield-text [maxLength]="40" [placeholder]="'Sprachen'" [editMode]="editMode === CaseSaveMode.EBS_Daten"
                            [(text)]="copiedActiveCase.uebersetzung_sprachen"></editfield-text>
          </td>
        </tr>
        <tr>
          <td>Übersetzungen vorhanden</td>
          <td></td>
          <td>
            <editfield-date [editMode]="editMode === CaseSaveMode.EBS_Daten" [displayUnbekanntAsNo]="true"
                            [(date)]="copiedActiveCase.uebersetzung_erledigt_date"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>Sicherungsaufforderung nötig</td>
          <td>
            <editfield-checkbox [editMode]="editMode === CaseSaveMode.EBS_Daten"
                                [(checkbox)]="copiedActiveCase.sicherungsaufforderung_noetig"></editfield-checkbox>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>eidestattliche Erklärung</td>
          <td></td>
          <td>
            <editfield-select [(select)]="copiedActiveCase.esa_eidesstattliche_erklaerung"
                                [editMode]="editMode === CaseSaveMode.EBS_Daten" [options]="erklaerunsOptions"></editfield-select>
          </td>
        </tr>
        <tr>
          <td>ESA eingereicht</td>
          <td>
            <editfield-select *ngIf="editMode === CaseSaveMode.EBS_Daten" [(select)]="copiedActiveCase.esa_eingereicht_user" [editMode]="true" [options]="userOptions"></editfield-select>
            <span *ngIf="editMode !== CaseSaveMode.EBS_Daten" class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(copiedActiveCase.esa_eingereicht_user)}}</span>
          </td>
          <td>
            <editfield-date [editMode]="editMode === CaseSaveMode.EBS_Daten" [displayUnbekanntAsNo]="true"
                            [(date)]="copiedActiveCase.esa_eingereicht_date"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>Erbe für EV</td>
          <td></td>
          <td>
            <editfield-select *ngIf="editMode === CaseSaveMode.EBS_Daten" [(select)]="copiedActiveCase.erbe_fuer_ev"
                                [editMode]="true" [options]="erbenOptions"></editfield-select>
            <span *ngIf="editMode !== CaseSaveMode.EBS_Daten">{{displayPersonData(copiedActiveCase.erbe_fuer_ev)}}</span>
            <span *ngIf="editMode !== CaseSaveMode.EBS_Daten && copiedActiveCase.erbe_fuer_ev > 0">
              <button class="btn btn-primary btn-sm mx-1" (click)="onSelectPerson(copiedActiveCase.erbe_fuer_ev)"><span class="fa fa-info"></span></button>
            </span>
          </td>
        </tr>
        <tr>
          <td>Notar für EV</td>
          <td></td>
          <td><addressbook-autocomplete [editMode]="editMode === CaseSaveMode.EBS_Daten"
                                        [(fieldValue)]="copiedActiveCase.notar_fuer_ev"></addressbook-autocomplete></td>
        </tr>
        <tr>
          <td>EV veranlasst</td>
          <td>
            <editfield-select *ngIf="editMode === CaseSaveMode.EBS_Daten" [(select)]="copiedActiveCase.ev_veranlasst_user" [editMode]="true" [options]="userOptions"></editfield-select>
            <span *ngIf="editMode !== CaseSaveMode.EBS_Daten" class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(copiedActiveCase.ev_veranlasst_user)}}</span>
          </td>
          <td>
            <editfield-date [editMode]="editMode === CaseSaveMode.EBS_Daten" [displayUnbekanntAsNo]="true"
                            [(date)]="copiedActiveCase.ev_veranlasst_date"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>EV erfolgt</td>
          <td></td>
          <td>
            <editfield-date [editMode]="editMode === CaseSaveMode.EBS_Daten" [displayUnbekanntAsNo]="true"
                            [(date)]="copiedActiveCase.ev_erfolgt_date"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>RA nötig</td>
          <td>
            <editfield-checkbox [editMode]="editMode === CaseSaveMode.EBS_Daten"
                                [(checkbox)]="copiedActiveCase.ra_noetig"></editfield-checkbox>
          </td>
          <td>
            <editfield-text [maxLength]="40" [placeholder]="'Name'" [editMode]="editMode === CaseSaveMode.EBS_Daten"
                            [(text)]="copiedActiveCase.ra_name"></editfield-text>
          </td>
        </tr>
        <tr>
          <td>EBS erlassen</td>
          <td></td>
          <td>
            <editfield-date [editMode]="editMode === CaseSaveMode.EBS_Daten" [displayUnbekanntAsNo]="true"
                            [(date)]="copiedActiveCase.ebs_erlassen_date"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>EBS in HD eingetroffen</td>
          <td>
            <editfield-select *ngIf="editMode === CaseSaveMode.EBS_Daten" [(select)]="copiedActiveCase.ebs_eingetroffen_user" [editMode]="true" [options]="userOptions"></editfield-select>
            <span *ngIf="editMode !== CaseSaveMode.EBS_Daten" class="badge badge-default badge-pill">{{userService.getAbbreviationForUser(copiedActiveCase.ebs_eingetroffen_user)}}</span>
          </td>
          <td>
            <editfield-date [editMode]="editMode === CaseSaveMode.EBS_Daten" [displayUnbekanntAsNo]="true"
                            [(date)]="copiedActiveCase.ebs_eingetroffen_date"></editfield-date>
          </td>
        </tr>
        <tr>
          <td>sonstiges</td>
          <td></td>
          <td><textarea [disabled]="editMode !== CaseSaveMode.EBS_Daten" class="form-control" rows="5"
                        [(ngModel)]="copiedActiveCase.verbleib_geld"></textarea></td>
        </tr>
      </table>
    </div>
  </div>
</div>
