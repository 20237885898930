<h5>offene Kostenpositionen</h5>
<div class="row mb-2">
  <div class="col-4">
    <form [formGroup]="form">
      <input type="text" class="form-control form-control-sm" formControlName="filterstring" placeholder="Anzeige filtern..." id="filterstring">
    </form>
  </div>
</div>

<table class="table table-striped table-sm">
  <tr>
    <th></th>
    <th><app-table-sort-header (click)="setSortBy('Fall')" [title]="'Fall'" [isActive]="sortBy === 'Fall'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th><app-table-sort-header (click)="setSortBy('Kürzel')" [title]="'Kürzel'" [isActive]="sortBy === 'Kürzel'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th><app-table-sort-header (click)="setSortBy('Position')" [title]="'Position'" [isActive]="sortBy === 'Position'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th><app-table-sort-header (click)="setSortBy('Betrag')" [title]="'Betrag'" [isActive]="sortBy === 'Betrag'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th>RG</th>
    <th><app-table-sort-header (click)="setSortBy('Kategorie')" [title]="'Kategorie'" [isActive]="sortBy === 'Kategorie'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th><app-table-sort-header (click)="setSortBy('MA')" [title]="'MA'" [isActive]="sortBy === 'MA'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th><app-table-sort-header (click)="setSortBy('Belegnummer')" [title]="'Belegnummer'" [isActive]="sortBy === 'Belegnummer'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th><app-table-sort-header (click)="setSortBy('Beleg-Datum')" [title]="'Beleg-Datum'" [isActive]="sortBy === 'Beleg-Datum'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th><app-table-sort-header (click)="setSortBy('Zahlung')" [title]="'Zahlung'" [isActive]="sortBy === 'Zahlung'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th><app-table-sort-header (click)="setSortBy('durch')" [title]="'durch'" [isActive]="sortBy === 'durch'" [isInverse]="sortInverse"></app-table-sort-header></th>
    <th></th>
    <th title="erledigt">erled.</th>
    <th></th>
  </tr>
  <span *ngIf="!kostenpositionenSorted || kostenpositionenSorted.length === 0">
    Keine Kostenpositionen vorhanden.
  </span>
  <ng-container *ngFor="let k of kostenpositionenSorted">
    <tr [ngClass]="{'beleg_deleted': k.deleted}">
      <td (click)="toggleEinzelposten(k.id)" style="color: darkblue;">
        <span *ngIf="k.id === kostenpositionExpanded"><i class="fa fa-caret-down"><span *ngIf="k.einzelposten.length > 1">{{k.einzelposten.length}}</span></i></span>
        <span *ngIf="k.id !== kostenpositionExpanded"><i class="fa fa-caret-right"><span *ngIf="k.einzelposten.length > 1">{{k.einzelposten.length}}</span></i></span>
      </td>
      <td>{{k.fall}}</td>
      <td>{{getArtOptionText(k.art)}}</td>
      <td>{{k.belegtitel}}</td>
      <td>
        <span *ngIf="!k.betrag_invalid && k.art !== -1">{{k.betrag_brutto | number: '1.2-2'}}</span>
        <span *ngIf="!k.betrag_invalid && k.art === -1" style="color: red;">-{{k.betrag_brutto | number: '1.2-2'}}</span>
        <span *ngIf="k.betrag_invalid" title="Es gibt noch in Fremdwährung angegebene Positionen" class="red mx-2 fa fa-exclamation-triangle"></span>
      </td>
      <td>
        <span *ngIf="k.rechnungsstellung === 0" title="Betrag wird den Erben nicht in Rechnung gestellt" class="fa fa-times-circle" style="color: red;"></span>
        <span *ngIf="k.rechnungsstellung === 1" title="Betrag wird den Erben in Rechung gestellt" class="fa fa-check-circle" style="color: darkgreen;"></span>
        <span *ngIf="k.rechnungsstellung === 2" title="Betrag wird den Erben teilweise in Rechnung gestellt" class="fa fa-check-circle-o" style="color: darkgoldenrod;"></span>
      </td>
      <td>{{k.kostenkategorie}}</td>
      <td><span class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(k.von_mitarbeiter)}}</span></td>
      <td>{{k.belegnummer}}</td>
      <td>{{k.belegdatum | date:'dd.MM.y'}}</td>
      <td>
        <span *ngIf="k.betrag_bezahlt_am" [title]="kassenbuchService.getHintForPaymentOption(k.payment_method)" class="paymentKuerzel">{{kassenbuchService.getKuerzelForPaymentOption(k.payment_method)}}</span>
        <span *ngIf="k.betrag_bezahlt_am">{{k.betrag_bezahlt_am | date:'dd.MM.y'}}</span>
      </td>
      <td><span class="badge badge-pill badge-default">{{userService.getAbbreviationForUser(k.betrag_bezahlt_durch)}}</span></td>
      <td>
        <span (click)="showFile(k.dateien[0].id)" style="cursor: pointer; color: darkblue;" *ngIf="k.dateien.length > 0">
          <span class="fa fa-file-alt mx-1"></span>{{k.dateien.length}}
        </span>
      </td>
      <td>
        <show-boolean-value *ngIf="!k.deleted" [value]="k.done"></show-boolean-value>
        <span *ngIf="k.deleted" class="fa fa-trash red"></span>
      </td>
      <td>
        <button title="Detail-Infos ein-/ausklappen" class="bw btn btn-sm btn-info mx-1" (click)="toggleEinzelposten(k.id)">
          <span *ngIf="kostenpositionExpanded !== k.id" class="fa fa-caret-right"></span>
          <span *ngIf="kostenpositionExpanded === k.id" class="fa fa-caret-down"></span>
        </button>
        <button *ngIf="!k.betrag_bezahlt_am" [ngClass]="{'invis': k.deleted}" title="auf bezahlt setzen" class="bw btn btn-success btn-sm mx-1" (click)="doPayment(k, true)"><span class="fa fa-credit-card"></span></button>
        <button *ngIf="k.betrag_bezahlt_am" [ngClass]="{'invis': k.deleted}" title="Status bearbeiten" class="bw btn btn-sm btn-default mx-1" (click)="editStatus(k, true)"><span class="fa fa-cog"></span></button>
        <button *ngIf="!k.deleted" title="löschen" class="bw btn btn-sm btn-danger mx-1" (click)="deleteKostenposition(k, true)"><span class="fa fa-trash"></span></button>
        <button *ngIf="k.deleted" title="löschen rückgängig machen" class="bw btn btn-sm btn-danger mx-1" (click)="undoDeleteKostenposition(k, true)"><span class="fa fa-lightbulb"></span></button>
      </td>
    </tr>
    <tr *ngIf="kostenpositionExpanded === k.id">
      <td></td>
      <td colspan="12" class="bordered-aufklapp">
        <div class="row">
          <div class="col-3"><b>Titel: {{k.belegtitel}}</b></div>
          <div class="col-3" style="cursor: pointer;"><b>Dateien:</b> <span *ngFor="let f of k.dateien; let i = index;" class="badge badge-success mx-1" (click)="showFile(f.id)">{{i + 1}}</span></div>
          <div class="col-6"><b>Kommentar:</b> {{k.erlaeuterung}}</div>
        </div>
        <table class="table table-sm">
          <tr><th>Position</th><th>Brutto-Betrag</th><th>Netto-Betrag</th><th>UST-Satz</th><th>Kategorie</th><th title="in Rechnung stellen">Rechnungsstellung</th></tr>
          <tr *ngFor="let ep of k.einzelposten">
            <td>{{ep.postentitel}}</td>
            <td>{{getWaehrungString(ep.waehrung)}} {{ep.betrag_brutto | number: '1.2-2'}}<button *ngIf="ep.waehrung > 0" class="mx-2 pull-right btn btn-sm btn-primary" (click)="waehrungUmrechnen(ep, true)">umrechnen</button></td>
            <td>{{getWaehrungString(ep.waehrung)}} {{ep.betrag_netto | number: '1.2-2'}}</td>
            <td>{{ep.ust_satz}} %</td>
            <td>{{kassenbuchService.getKostenkategorieName(ep.kostenkategorie)}}</td>
            <td>
              <span *ngIf="!ep.in_rechnung_stellen" title="Betrag wird den Erben nicht in Rechnung gestellt" class="fa fa-times-circle" style="color: red;"></span>
              <span *ngIf="ep.in_rechnung_stellen" title="Betrag wird den Erben in Rechung gestellt" class="fa fa-check-circle" style="color: darkgreen;"></span>
            </td>
          </tr>
        </table>
        <div *ngIf="k.verlaufsprotokoll">
          <div><b>Verlaufsprotokoll</b></div>
          <table class="table table-sm">
            <tr *ngFor="let p of k.verlaufsprotokoll">
              <td>{{p.created | date:'dd.MM.y HH:mm:ss'}}</td>
              <td>{{p.text}}</td>
            </tr>
          </table>
        </div>
      </td>
      <td></td>
    </tr>
  </ng-container>
</table>
